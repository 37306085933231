





















import { createEmptyEditUser } from '@/entities/mappers/Factory'
import StoreMapper from '@/entities/mappers/StoreMapper'
import { EditUser } from '@/entities/user/EditUser'
import { UserRole } from '@/entities/user/User'
import { trsl } from '@/i18n/lang'
import store from '@/store'
import { ValidationObserver } from 'vee-validate'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import UserEditor from './UserEditor.vue'

@Component({
    components: {
        UserEditor,
    },
})
export default class UserEditorModal extends Vue {
    @Prop() value!: boolean
    @Prop() userId?: number
    @Prop() kickstart?: boolean

    user: EditUser = createEmptyEditUser(this.defaultRole)
    $refs!: {
        validator: InstanceType<typeof ValidationObserver>
    }

    get show() {
        return this.value
    }

    set show(newValue: boolean) {
        this.$emit('input', newValue)
    }

    get defaultRole() {
        return this.kickstart ? UserRole.ADMIN : UserRole.VISITOR
    }

    @Watch('show')
    updateUser() {
        if (this.show) {
            const user = store.getters.user.userWithId(this.userId || null)
            this.user = user ? StoreMapper.mapEditUserFromUser(user) : createEmptyEditUser(this.defaultRole)
        }
    }

    get title() {
        return this.userId ? trsl('user.action.edit') : trsl('user.action.create')
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    preventHide(event: any) {
        if (event.trigger == 'backdrop') event.preventDefault()
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    submit(event: any) {
        event.preventDefault()
        this.$refs.validator.validate().then(success => {
            if (success) {
                const save = this.kickstart
                    ? store.dispatch.user.kickstartWithUser(this.user)
                    : store.dispatch.user.saveUser(this.user)

                save.then(id => {
                    this.$emit('save', id)
                    this.show = false
                    store.commit.message.showToast({ textKey: 'success.save-user', variant: 'success' })
                })
            }
        })
    }

    deleteUser() {
        const user = store.getters.user.userWithId(this.userId || null)
        if (!user) return

        let eventUnassignedPromise: Promise<boolean>
        if (user.events.length == 0) eventUnassignedPromise = Promise.resolve(true)
        else {
            eventUnassignedPromise = store.dispatch.message
                .showDialog({ textKey: 'unassign-all-events', confirmKey: 'unassign' })
                .then(confirmed => {
                    if (confirmed) {
                        return store.dispatch.user.manageEvents({ userId: user.id, newEventIds: [] }).then(() => {
                            return true
                        })
                    } else {
                        return false
                    }
                })
        }

        eventUnassignedPromise.then(unassigned => {
            if (unassigned) {
                store.dispatch.message.showDeleteDialog('delete-user').then(confirmed => {
                    if (confirmed)
                        store.dispatch.user.deleteUserWithId(user.id).then(() => {
                            store.commit.message.showToast({ textKey: 'success.delete-user', variant: 'success' })
                            this.$emit('deleted')
                        })
                })
            }
        })
    }
}
