













































































import { EditUser } from '@/entities/user/EditUser'
import { UserRole } from '@/entities/user/User'
import { generatePassword } from '@/utils/Utils'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class UserEditor extends Vue {
    @Prop() value!: EditUser
    @Prop() kickstart?: boolean

    get isEditing() {
        return this.value.id != null
    }

    generatePassword() {
        this.value.password = generatePassword()
    }

    get isVisitor() {
        return this.value.role == UserRole.VISITOR
    }

    set isVisitor(newValue: boolean) {
        if (newValue) this.value.role = UserRole.VISITOR
    }

    get isAdmin() {
        return this.value.role == UserRole.ADMIN
    }

    set isAdmin(newValue: boolean) {
        if (newValue) this.value.role = UserRole.ADMIN
    }

    get isJudge() {
        return this.value.role == UserRole.JUDGE
    }

    set isJudge(newValue: boolean) {
        if (newValue) this.value.role = UserRole.JUDGE
    }
}
