






















import { Vue, Component } from 'vue-property-decorator'
import UserList from '@/components/user/UserList.vue'
import UserEditorModal from '@/components/user/UserEditorModal.vue'
import store from '@/store'
import { Route } from '@/router'

@Component({
    components: {
        UserList,
        UserEditorModal,
    },
})
export default class UserView extends Vue {
    mounted() {
        store.dispatch.user.fetchUsers()
    }

    get users() {
        return store.getters.user.users
    }

    showEditorModal = false
    create() {
        this.showEditorModal = true
    }

    pushToUserWithId(id: number) {
        this.$router.push({ name: Route.USER_DETAILS, params: { userId: `${id}` } })
    }
}
