export function sort(a: string | null | undefined, b: string | null | undefined): number {
    const aSort = (a || '').toLowerCase()
    const bSort = (b || '').toLowerCase()
    return aSort < bSort ? -1 : aSort > bSort ? 1 : 0
}

export function generatePassword(passwordSections?: number, charsPerSection?: number) {
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
    const sections = passwordSections || 3
    const charsPer = charsPerSection || 4

    let password = ''
    for (let i = 0; i < sections; i++) {
        if (i > 0) password += '-'
        for (let j = 0; j < charsPer; j++) {
            const randomNumber = Math.floor(Math.random() * chars.length)
            password += chars.slice(randomNumber, randomNumber + 1)
        }
    }
    return password
}
